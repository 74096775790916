import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';

export default {
  getSystemRating(ctx) {
    ctx.$store.commit('toggleLoadingSystemRating');

    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/rating/system`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        timebounds: { start, stop }
      })
      .then(
        (response) => {
          ctx.currentSystemRating = response.data.data.itemslist;
          ctx.$store.commit('setSystemRatingList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingSystemRating');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingSystemRating');
          //
        }
      );
  },

  responseStats(ctx) {
    ctx.clearChartData();
    ctx.$store.commit('toggleLoadingStats');
    // depends on requests count below
    const PromiseCounter = 2;

    // Выставляем границы графика по оси X

    const periodStart = ctx.drPickerDate.startDate / 1000;
    let periodStop = ctx.drPickerDate.endDate / 1000;
    if (periodStop * 1000 > Date.now()) {
      periodStop = Date.now() / 1000;
    }
    const minXAxis = new Date(periodStart * 1000);
    const maxXAxis = new Date(periodStop * 1000);

    ctx.txRxChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.txRxRateChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxRateChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.clientsChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.clientsChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.uniqueClientsChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.uniqueClientsChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.txRxChartPerUser.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxChartPerUser.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.txRxRateChartPerUser.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxRateChartPerUser.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.memoryCharts.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.memoryCharts.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.cpuCharts.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.cpuCharts.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    const start = moment(ctx.drPickerDate.startDate).unix();
    const stop = moment(ctx.drPickerDate.endDate).unix();
    ctx.clearChartData();
    let aggregated;
    if (ctx.isAggregated) {
      aggregated = true;
    } else {
      aggregated = false;
    }

    // Total TX/RX chart
    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/timeline/system`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        timebounds: { start, stop },
        q: {
          location_id: ctx.systemSelectedId,
          aggregated
        }
      })
      .then(
        (response) => {
          let rawStats = response.data.data.itemslist;
          rawStats = rawStats.sort((prev, curr) => {
            if (prev.timestamp > curr.timestamp) {
              return 1;
            }
            if (prev.timestamp < curr.timestamp) {
              return -1;
            }
            return 0;
          });

          rawStats.forEach((item) => {
            const time = new Date(item.timestamp * 1000);
            ctx.txRxChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxChart.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxChart.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat(
                (
                  parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2)) +
                  parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
                ).toFixed(2)
              )
            ]);

            ctx.clientsChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients
            ]);
            ctx.uniqueClientsChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.unique_clients
            ]);

            ctx.txRxChartPerUser.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_tx / item.total_clients / 1000 / 1000).toFixed(2)) : 0
            ]);
            ctx.txRxChartPerUser.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_rx / item.total_clients / 1000 / 1000).toFixed(2)) : 0
            ]);
            ctx.txRxChartPerUser.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ?
                parseFloat(
                  (
                    parseFloat((item.traffic_tx / item.total_clients / 1000 / 1000).toFixed(2)) +
                      parseFloat((item.traffic_rx / item.total_clients / 1000 / 1000).toFixed(2))
                  ).toFixed[2]
                ) :
                0
            ]);

            ctx.txRxRateChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxRateChart.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxRateChart.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat(
                (
                  parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(2)) +
                  parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(2))
                ).toFixed(2)
              )
            ]);

            ctx.txRxRateChartPerUser.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_tx_rate / item.total_clients / 1000 / 1000).toFixed(2)) : 0
            ]);
            ctx.txRxRateChartPerUser.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_rx_rate / item.total_clients / 1000 / 1000).toFixed(2)) : 0
            ]);
            ctx.txRxRateChartPerUser.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ?
                parseFloat((item.traffic_tx_rate / item.total_clients / 1000 / 1000).toFixed(2)) +
                  parseFloat((item.traffic_rx_rate / item.total_clients / 1000 / 1000).toFixed(2)) :
                0
            ]);

            let cpuPercent = item.cpu_load * 100;
            cpuPercent = Math.round(cpuPercent * 100) / 100;
            let memPercent = ((item.memory_total - item.memory_free) * 100) / item.memory_total;
            memPercent = Math.round(memPercent * 100) / 100;
            const arrayCPU = [
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              cpuPercent
            ];
            const arrayMemory = [
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              memPercent
            ];
            ctx.cpuCharts.series[0].data.push(arrayCPU);
            ctx.memoryCharts.series[0].data.push(arrayMemory);
          });

          ctx.$store.commit('toggleLoadingStats');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.$store.commit('toggleLoadingStats');
        }
      );
  }
};
