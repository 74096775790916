<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-3 col-md-12 mb-h">
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input
              id="search"
              name="search"
              :placeholder="$t('aps.search')"
              class="form-control filters__input"
              v-model="filters.query"
              @input="disableStatMode()"
            />
            <span class="clear-input text-muted" v-if="filters.query" @click="filters.query = ''">
              <i class="fa fa-remove"></i>
            </span>
          </div>
        </div>

        <!--        <div class="col-lg-4 col-md-12">-->
        <!--          <div class="input-group input-group-sm filters__input ">-->
        <!--                <span class="input-group-addon filters__field-icon">-->
        <!--                  <i class="fa fa-building-o"-->
        <!--                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
        <!--                </span>-->
        <!--            <select-->
        <!--              class="form-control form-control-sm filters__field"-->
        <!--              v-model="filters.company"-->
        <!--            >-->
        <!--              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}-->
        <!--              </option>-->
        <!--              <option v-for="company in companyList" :value="company" :key="company.name">-->
        <!--                {{ company.name}}-->
        <!--              </option>-->
        <!--            </select>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-lg-3 col-md-12 mb-h">
          <div class="input-group input-group-sm filters__input">
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
            </span>
            <select class="form-control form-control-sm filters__field" v-model="filters.company">
              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
              <option v-for="company in companys" :value="company" :key="company.name">
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 mb-h">
          <div class="input-group input-group-sm filters__sort">
            <span class="input-group-addon filters__field-icon">
              <i
                class="fa fa-sort-amount-desc text-primary icon-sort"
                v-if="currSortMode === 'desc'"
                @click="toggleSortMode()"
              ></i>
              <i
                class="fa fa-sort-amount-asc text-primary icon-sort"
                v-if="currSortMode === 'asc'"
                @click="toggleSortMode()"
              ></i>
            </span>
            <select class="form-control form-control-sm filters__field" v-model="sortByFilter">
              <option :selected="true" value="Location">{{ $t('statistics.byLocation') }}</option>
              <option :selected="true" value="Traffic">{{ $t('statistics.byTraffic') }}</option>
              <option value="Total Clients">{{ $t('statistics.bytotalClients') }}</option>
              <option value="CPU Load">{{ $t('statistics.byCPULoad') }}</option>
              <option value="Memory Load">{{ $t('statistics.byMemoryLoad') }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          <button v-if="isFiltersActive" class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">
            <i class="fa fa-close"></i>
            {{ $t('general.resetAllFilters') }}
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="systems" :class="{ 'stat-visible': statVisible }">
            <div class="systems-rating-list animated fadeIn">
              <div class="animated fadeIn sticky-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <!--                      <div class="loader loader-backdrop loader-backdrop-statsystem" v-if="isSystemRatingLoading"></div>-->
                      <div v-if="isSystemRatingLoading"><Loader-spinner /></div>
                      <div class="card-header">
                        <i class="fa fa-bar-chart"></i>
                        {{ $t('sidebar.system') }}
                        <div class="float-right">
                          <div class="d-flex justify-content-end align-items-start">
                            <div>
                              <date-picker
                                v-if="!statVisible"
                                v-model="drPickerDateForRating"
                                :onChange="handleDateChangeForRating.bind(this)"
                                @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForRating"
                                :show-error="isShowingDateRangeLimitExceededErrorForRating"
                                enable-max-date-range-limit
                              />
                              <div
                                v-show="isShowingDateRangeLimitExceededErrorForRating === true && !statVisible"
                                class="mb-h mb-1"
                              >
                                <span
                                  style="font-size: 0.8em;"
                                  v-show="isShowingDateRangeLimitExceededErrorForRating === true"
                                  class="help is-danger"
                                >
                                  {{ $t('general.dateRangeLimitExceededError') }}
                                  {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForRating).humanize() }}
                                </span>
                              </div>
                            </div>
                            <div>
                              <download-extract-v2
                                newXlsAutoColumnsWidth
                                v-if="systemRating && !statVisible"
                                class="ml-h"
                                :data="formatToCsv(filterItems(systemRating))"
                                :data-for-excel="formatAllStatsToExcel()"
                                excel-file-type="xlsx"
                                :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.statSystem'))"
                                :filename="getFileNameForDownloadFiles($t('downloadFilesNames.statSystem'))"
                                :downloads-formats-for-show="{
                                  oldCsv: false,
                                  newCsv: true,
                                  oldXls: false,
                                  newXls: true,
                                  pdf: true
                                }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-block">
                        <table class="table">
                          <thead>
                            <tr>
                              <th :class="{ 'table-row--sorted': sortByFilter === 'Location' && !statVisible }">
                                {{ $t('general.location') }}
                              </th>
                              <th v-if="!statVisible" :class="{ 'table-row--sorted': sortByFilter === 'Traffic' }">
                                {{ $t('general.trafficTxRx') }}
                              </th>
                              <th
                                v-if="!statVisible"
                                :class="{ 'table-row--sorted': sortByFilter === 'Total Clients' }"
                              >
                                {{ $t('general.totalClients') }}
                              </th>
                              <!--<th v-if="!statVisible" :class="{'table-row&#45;&#45;sorted': sortByFilter === 'Unique Clients'}">{{$t('general.uniqueClients')}}</th>-->
                              <th v-if="!statVisible" :class="{ 'table-row--sorted': sortByFilter === 'CPU Load' }">
                                {{ $t('general.cpuLoad') }}
                              </th>
                              <th v-if="!statVisible" :class="{ 'table-row--sorted': sortByFilter === 'Memory Load' }">
                                {{ $t('general.memoryLoad') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(system, index) in filterItems(systemRating)"
                              v-if="system.location_name === '/' && system.is_aggregated"
                              :key="system.location_id + system.is_aggregated"
                              :data-id="system.location_id"
                              :data-index="index"
                              :data-aggregated="system.is_aggregated"
                              @click="toggleEdit"
                              class="cpe-row"
                              :class="{
                                'table-row--sorted': system.is_aggregated && !statVisible,
                                'location-included': !system.is_aggregated && !statVisible,
                                'location-aggregated': system.is_aggregated && !statVisible,
                                selected:
                                  systemSelectedId === system.location_id && isAggregated == system.is_aggregated
                              }"
                            >
                              <td :class="{ 'table-row--sorted': sortByFilter === 'Location' && !statVisible }">
                                <img class="img img-responsive cpe-logo" src="/static/img/diagram.svg" alt />
                                <span v-if="system.location_name === '/' && system.is_aggregated">
                                  {{ $t('general.allLocations') }}
                                </span>
                              </td>
                              <td v-if="!statVisible" :class="{ 'table-row--sorted': sortByFilter === 'Traffic' }">
                                <span v-if="system.traffic_rx || system.traffic_rx == 0">
                                  {{ formatBytes(system.traffic_rx) }} /
                                </span>
                                <span v-if="system.traffic_tx || system.traffic_tx == 0">
                                  {{ formatBytes(system.traffic_tx) }}
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td
                                v-if="!statVisible"
                                class="responsive-row"
                                :class="{ 'table-row--sorted': sortByFilter === 'Total Clients' }"
                              >
                                <span v-if="system.total_clients || system.total_clients == 0">
                                  {{ system.total_clients }}
                                </span>
                                <span v-else>-</span>
                              </td>
                              <!--<td v-if="!statVisible" class="responsive-row" :class="{'table-row&#45;&#45;sorted': sortByFilter === 'Unique Clients'}">-->
                              <!--<span v-if="system.unique_clients || system.unique_clients == 0">{{system.unique_clients}}</span>-->
                              <!--<span v-else>-</span>-->
                              <!--</td>-->
                              <td v-if="!statVisible" :class="{ 'table-row--sorted': sortByFilter === 'CPU Load' }">
                                <span v-if="!system.cpu_load && system.cpu_load != 0">-</span>
                                <span v-else-if="system.cpu_load == 0">0%</span>
                                <span v-else>{{ (system.cpu_load * 100) | toFixed(2) }}%</span>
                              </td>
                              <td
                                v-if="!statVisible"
                                class="responsive-row"
                                :class="{ 'table-row--sorted': sortByFilter === 'Memory Load' }"
                              >
                                <span v-if="system.memory_total && system.memory_free">
                                  {{ memoryLoad(system.memory_total, system.memory_free) | toFixed(2) }}%
                                </span>
                                <span v-if="memoryLoad(system.memory_total, system.memory_free) == 0">0%</span>
                              </td>
                            </tr>

                            <tr
                              v-for="(system, index) in filterItems(systemRating)"
                              v-if="system.location_name !== '/' || !system.is_aggregated"
                              :key="system.location_id + system.is_aggregated"
                              :data-id="system.location_id"
                              :data-index="index"
                              :data-aggregated="system.is_aggregated"
                              @click="toggleEdit"
                              class="cpe-row"
                              :class="{
                                'disabled-when-date-range-limit-exceeded-error':
                                  isShowingDateRangeLimitExceededErrorForStat &&
                                  statVisible &&
                                  systemSelectedId !== system.location_id,
                                'table-row--sorted': system.is_aggregated && !statVisible,
                                selected:
                                  systemSelectedId === system.location_id && isAggregated === system.is_aggregated
                              }"
                            >
                              <td
                                :class="{
                                  'table-row--sorted': sortByFilter === 'Location' && !statVisible,
                                  'location-included': !system.is_aggregated && !statVisible,
                                  'location-aggregated': system.is_aggregated && !statVisible
                                }"
                              >
                                <img
                                  v-if="system.is_aggregated"
                                  class="img img-responsive cpe-logo"
                                  src="/static/img/diagram.svg"
                                  alt
                                />
                                <span v-tooltip.left="{ content: tooltipForLocationColumn(system) }">
                                  {{ system.location_name }}
                                </span>
                              </td>
                              <td
                                v-if="!statVisible"
                                :class="{ 'table-row--sorted': sortByFilter === 'Traffic' && !statVisible }"
                              >
                                <span v-if="system.traffic_rx || system.traffic_rx == 0">
                                  {{ formatBytes(system.traffic_rx) }} /
                                </span>
                                <span v-if="system.traffic_tx || system.traffic_tx == 0">
                                  {{ formatBytes(system.traffic_tx) }}
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td
                                v-if="!statVisible"
                                class="responsive-row"
                                :class="{ 'table-row--sorted': sortByFilter === 'Total Clients' }"
                              >
                                <span v-if="system.total_clients || system.total_clients == 0">
                                  {{ system.total_clients }}
                                </span>
                                <span v-else>-</span>
                              </td>
                              <!--<td v-if="!statVisible" class="responsive-row" :class="{'table-row&#45;&#45;sorted': sortByFilter === 'Unique Clients'}">-->
                              <!--<span v-if="system.unique_clients || system.unique_clients == 0">{{system.unique_clients}}</span>-->
                              <!--<span v-else>-</span>-->
                              <!--</td>-->
                              <td v-if="!statVisible" :class="{ 'table-row--sorted': sortByFilter === 'CPU Load' }">
                                <span v-if="!system.cpu_load && system.cpu_load != 0">-</span>
                                <span v-else-if="system.cpu_load == 0">0%</span>
                                <span v-else>{{ (system.cpu_load * 100) | toFixed(2) }}%</span>
                              </td>
                              <td
                                v-if="!statVisible"
                                class="responsive-row"
                                :class="{ 'table-row--sorted': sortByFilter === 'Memory Load' }"
                              >
                                <span v-if="system.memory_total && system.memory_free">
                                  {{ memoryLoad(system.memory_total, system.memory_free) | toFixed(2) }}%
                                </span>
                                <span v-if="memoryLoad(system.memory_total, system.memory_free) == 0">0%</span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                colspan="9"
                                class="no-data-row"
                                v-if="!filterItems(systemRating).length || !systemRatingListLength"
                              >
                                <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="system-stat" id="system-stat" v-if="statVisible">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card sticky-top">
                    <!--                    <div class="loader loader-backdrop loader-backdrop-statsystem" v-if="isStatsLoading"></div>-->
                    <div v-if="isStatsLoading"><Loader-spinner /></div>
                    <div class="card-header">
                      <i class="fa fa-map-o"></i>
                      {{ locationName(systemSelectedId) }}
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm float-right new-modern-style-btn-close-panel"
                        @click="disableStatMode"
                        data-html2canvas-ignore
                      >
                        <i class="fa fa-close"></i>
                      </button>
                    </div>
                    <div class="card-block">
                      <div class="row">
                        <div class="col-lg-12" data-html2canvas-ignore>
                          <div class="d-flex justify-content-end align-items-start">
                            <div>
                              <date-picker
                                v-model="drPickerDate"
                                class="float-right"
                                :onChange="handleDateChangeForStat.bind(this)"
                                @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForStat"
                                :show-error="isShowingDateRangeLimitExceededErrorForStat"
                                enable-max-date-range-limit
                              />
                              <div v-show="isShowingDateRangeLimitExceededErrorForStat === true" class="mb-h mb-1">
                                <span
                                  style="font-size: 0.8em;"
                                  v-show="isShowingDateRangeLimitExceededErrorForStat === true"
                                  class="help is-danger"
                                >
                                  {{ $t('general.dateRangeLimitExceededError') }}
                                  {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForStat).humanize() }}
                                </span>
                              </div>
                            </div>
                            <div>
                              <download-extract-v2
                                class="ml-h float-right"
                                :data="formatToCsv(filterItems(systemRating))"
                                :data-for-excel="formatToExcel(filterItems(systemRating))"
                                excel-file-type="xlsx"
                                :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.statSystem'))"
                                :filename="getFileNameForDownloadFiles($t('downloadFilesNames.statSystem'))"
                                :consolidated="{
                                  enabled: true,
                                  selector: '#system-stat',
                                  customFilename: getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}`)
                                }"
                                :downloads-formats-for-show="{
                                  oldCsv: false,
                                  newCsv: false,
                                  oldXls: false,
                                  newXls: false,
                                  pdf: false
                                }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="row mt-1">
                            <div class="col-lg-12">
                              <highcharts :options="txRxChart" id="txRxChart" ref="highcharts"></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="txRxChart"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.txRxChartTitle')}`)"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-12">
                              <highcharts :options="txRxRateChart" id="txRxRateChart" ref="highcharts"></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="txRxRateChart"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.txRxRateChartTitle')}`)"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-12">
                              <highcharts :options="clientsChart" id="clientsChart" ref="highcharts"></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="clientsChart"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.totalClientsChartTitle')}`)"
                              />
                            </div>
                          </div>
                          <!--<div class="row mt-3">-->
                          <!--<div class="col-lg-12">-->
                          <!--<highcharts :options="uniqueClientsChart" ref="highcharts"></highcharts>-->
                          <!--</div>-->
                          <!--</div>-->
                          <div class="row mt-3">
                            <div class="col-lg-12">
                              <highcharts
                                :options="txRxChartPerUser"
                                id="txRxChartPerUser"
                                ref="highcharts"
                              ></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="txRxChartPerUser"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.totalTxRxPerUserChartTitle')}`)"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-12">
                              <highcharts :options="txRxRateChartPerUser" id="txRxRateChartPerUser" ref="highcharts"></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="txRxRateChartPerUser"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.txRxRatePerUserChartTitle')}`)"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-12">
                              <highcharts :options="cpuCharts" id="cpuCharts" ref="highcharts"></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="cpuCharts"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.cpuLoadChartTitle')}`)"
                              />
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-12">
                              <highcharts :options="memoryCharts" id="memoryCharts" ref="highcharts"></highcharts>
                              <SavePngButton
                                class="save-icon color-brand"
                                id="memoryCharts"
                                :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statSystem')}-${locationName(systemSelectedId)}_${$t('statistics.memoryLoadChartTitle')}`)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="actions-buttons">
                      <div>
                        <button type="button" class="btn btn-outline-secondary btn-md" @click="disableStatMode">
                          {{ $t('general.close') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '../../components/date-picker.vue';
import Filters from '../../filters';
import statSystemService from '../../services/statSystemService';
import commonService from '../../services/commonService';
import SavePngButton from '../../components/save-png-button.vue';
import helpers from '../../helpers';

export default {
  name: 'Statsystem',
  components: { SavePngButton, DatePicker },
  data() {
    return {
      isShowingDateRangeLimitExceededErrorForRating: false,
      dateRangeLimitExceededErrorRangeLimitForRating: 0,
      isShowingDateRangeLimitExceededErrorForStat: false,
      dateRangeLimitExceededErrorRangeLimitForStat: 0,
      isAggregated: false,
      filteredSystems: [],
      currentSystemRating: [],
      statSystemId: '',
      statSystemAggregated: '',
      filters: {
        query: '',
        company: 'All companys'
      },
      sortMode: 'desc',
      sortByFilter: 'Location',
      drPickerDate: {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      },
      drPickerDateForRating: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      txRxChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.txRxChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.txRxChartMbytes')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`
        },
        series: [
          {
            name: this.$t('statistics.txRxChartTX'),
            color: '#0277BD',
            data: []
          },
          {
            name: this.$t('statistics.txRxChartRX'),
            color: '#81C784',
            data: []
          },
          {
            name: this.$t('statistics.txRxChartTXandRX'),
            color: '#E57373',
            data: []
          }
        ]
      },
      txRxRateChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.txRxRateChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.txRxRateChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            name: this.$t('statistics.txRxRateChartTXRate'),
            color: '#0277BD',
            data: []
          },
          {
            name: this.$t('statistics.txRxRateChartRXRate'),
            color: '#81C784',
            data: []
          },
          {
            name: this.$t('statistics.txRxRateChartTXandRXRate'),
            color: '#E57373',
            data: []
          }
        ]
      },
      clientsChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.totalClientsChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: this.$t('statistics.totalClientsChartAxis')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            name: this.$t('statistics.totalClientsChartSeries'),
            data: [],
            color: '#20a8d8'
          }
        ]
      },
      uniqueClientsChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: 'Unique Clients',
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: 'Unique Clients'
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            name: 'Unique Clients',
            data: [],
            color: '#81C784'
          }
        ]
      },
      txRxChartPerUser: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.totalTxRxPerUserChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.totalTxRxPerUserChartMbytes')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`
        },
        series: [
          {
            name: this.$t('statistics.totalTxRxPerUserChartTX'),
            color: '#0277BD',
            data: []
          },
          {
            name: this.$t('statistics.totalTxRxPerUserChartRX'),
            color: '#81C784',
            data: []
          },
          {
            name: this.$t('statistics.totalTxRxPerUserChartTXandRX'),
            color: '#E57373',
            data: []
          }
        ]
      },
      txRxRateChartPerUser: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.txRxRatePerUserChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.txRxRatePerUserChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            name: this.$t('statistics.txRxRatePerUserChartTXRate'),
            color: '#0277BD',
            data: []
          },
          {
            name: this.$t('statistics.txRxRatePerUserChartRXRate'),
            color: '#81C784',
            data: []
          },
          {
            name: this.$t('statistics.txRxRatePerUserChartTXandRXRate'),
            color: '#E57373',
            data: []
          }
        ]
      },
      memoryCharts: {
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.memoryLoadChartTitle'),
          x: -20 // center
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.memoryLoadChartAxis')
          },
          min: 0,
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>'
        },
        series: [
          {
            name: this.$t('statistics.memoryLoadChartSeries'),
            color: '#0277BD',
            data: []
          }
        ]
      },
      cpuCharts: {
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.cpuLoadChartTitle'),
          x: -20 // center
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.cpuLoadChartAxis')
          },
          labels: {
            formatter() {
              return `${this.value}%`;
            }
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>'
        },
        series: [
          {
            name: this.$t('statistics.cpuLoadChartSeries'),
            color: '#E57373',
            data: []
          }
        ]
      }
    };
  },
  computed: {
    systemRatingListLength() {
      return this.$store.state.systemRatingList.length;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    isFiltersActive() {
      return (
        this.filters.query !== '' ||
        this.filters.company !== 'All companys' || this.sortMode !== 'desc' ||
          this.sortByFilter !== 'Location'
      );
    },
    isStatsLoading() {
      return this.$store.state.loadingStats;
    },
    systemRating() {
      const systemRating = this.$store.state.systemRatingList;
      if (systemRating) {
        systemRating.forEach((system) => {
          const findLocation = commonService.locationObjbyId(system.location_id);
          if (findLocation) {
            system.location_name = findLocation.name;
          }
        });
      }
      return systemRating;
    },
    statVisible() {
      return this.$store.state.statLocationId;
    },
    systemSelectedId() {
      return this.$store.state.statLocationId;
    },
    currSortMode() {
      return this.sortMode;
    },
    isSystemRatingLoading() {
      return this.$store.state.loadingSystemRating;
    }
  },
  watch: {},
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    dateRangeLimitEnabledAndExceededForRating(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForRating = true;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
        this.$store.commit('setSystemRatingList', []);
      } else {
        this.isShowingDateRangeLimitExceededErrorForRating = false;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
      }
    },
    dateRangeLimitEnabledAndExceededForStat(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForStat = true;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
        this.clearChartData();
      } else {
        this.isShowingDateRangeLimitExceededErrorForStat = false;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
      }
    },
    filterByCompany(items, locationId) {
      // console.log(locationId)
      let locationIDs = [];
      if (locationId === null || locationId === undefined) {
        locationIDs = [];
      } else {
        locationIDs = JSON.parse(JSON.stringify(locationId));
      }
      return items.filter((item) => {
        if (locationIDs.includes(item.location_id)) {
          return true;
        }
        return false;
      });
    },
    resetAllFilters() {
      this.filters.query = '';
      this.filters.company = 'All companys';
      this.sortMode = 'desc';
      this.sortByFilter = 'Location';
    },
    tooltipForLocationColumn(system) {
      try {
        const locationId = system.location_id;
        let result = '';
        if (this.locationPartyGetField(locationId, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          result = this.locationPartyGetField(locationId, 'name');
        } else {
          result = this.$t('statistics.noCompanyNameTooltipForTable');
        }
        return result;
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    locationPartyGetField(id, field) {
      try {
        const showLocationPartyFromId = commonService.showLocationPartyFromLocationId(id);
        return showLocationPartyFromId[field];
      } catch (e) {
        // console.log(e);
        return '';
      }
    },
    toggleEdit(e) {
      if (this.isShowingDateRangeLimitExceededErrorForStat && this.statVisible) {
        return;
      }

      const selectedNode = e.target.closest('[data-index]');
      const { aggregated, id } = selectedNode.dataset;
      this.isAggregated = !!aggregated;
      this.$store.commit('setLocationStatId', id);
      statSystemService.responseStats(this);
    },
    filterItems(items) {
      // console.log(items);
      let filteredSystems = items;
      filteredSystems = Filters.locationSearchQuery(filteredSystems, this.filters.query);
      if (this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') {
        // filteredSystems = this.filterByCompany(filteredSystems, this.filters.company.locationId);
        filteredSystems = this.filterByCompany(filteredSystems, this.filters.company.locations);
      }
      this.filteredSystems = filteredSystems;
      let sortedFilteredSystems = [];
      let first;
      let second;
      if (this.sortMode === 'desc') {
        first = -1;
        second = 1;
      } else if (this.sortMode === 'asc') {
        first = 1;
        second = -1;
      }

      sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
        if (curr.is_aggregated > prev.is_aggregated) {
          return first;
        }
        if (curr.is_aggregated < prev.is_aggregated) {
          return second;
        }
        return 0;
      });

      if (this.sortByFilter === 'Traffic') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (curr.traffic_rx > prev.traffic_rx) {
            return first;
          }
          if (curr.traffic_rx < prev.traffic_rx) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Location') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (curr.location_name < prev.location_name) {
            return first;
          }
          if (curr.location_name > prev.location_name) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Total Clients') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (curr.total_clients > prev.total_clients) {
            return first;
          }
          if (curr.total_clients < prev.total_clients) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Unique Clients') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (curr.unique_clients > prev.unique_clients) {
            return first;
          }
          if (curr.unique_clients < prev.unique_clients) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Passerby Clients') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (curr.passerby_clients > prev.passerby_clients) {
            return first;
          }
          if (curr.passerby_clients < prev.passerby_clients) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'CPU Load') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (curr.cpu_load > prev.cpu_load) {
            return first;
          }
          if (curr.cpu_load < prev.cpu_load) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Memory Load') {
        sortedFilteredSystems = filteredSystems.sort((curr, prev) => {
          if (
            this.memoryLoad(curr.memory_total, curr.memory_free) > this.memoryLoad(prev.memory_total, prev.memory_free)
          ) {
            return first;
          }
          if (
            this.memoryLoad(curr.memory_total, curr.memory_free) < this.memoryLoad(prev.memory_total, prev.memory_free)
          ) {
            return second;
          }
          return 0;
        });
      }
      // console.log(sortedFilteredSystems);
      return sortedFilteredSystems;
    },
    formatToExcel(data, graphName = 'data') {
      if (data.length === 0) {
        return { [graphName]: [] };
      }
      const result = [];
      data.forEach((row) => {
        let companyName = '';
        if (this.locationPartyGetField(row.location_id, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          companyName = this.locationPartyGetField(row.location_id, 'name');
        } else {
          companyName = this.$t('general.no').toLowerCase();
        }
        // console.log(row)
        if (result.length === 0) {
          result.push([
            // 'Location',
            // 'Company',
            // 'Traffic Downloaded',
            // 'Traffic Uploaded',
            // 'Total Clients',
            // 'CPU Load',
            // 'Memory Load'
            this.$t('general.location'),
            this.$t('general.company'),
            this.$t('general.trafficDownloaded'),
            this.$t('general.trafficUploaded'),
            this.$t('general.totalClients'),
            this.$t('general.cpuLoad'),
            this.$t('general.memoryLoad')
          ]);
        }
        result.push([
          row.location_name,
          companyName,
          this.formatBytes(row.traffic_rx),
          this.formatBytes(row.traffic_tx),
          row.total_clients,
          row.cpu_load ? `${(row.cpu_load * 100).toFixed(2)}%` : '0%',
          row.memory_total && row.memory_free ?
            `${this.memoryLoad(row.memory_total, row.memory_free).toFixed(2)}%` :
            '0%'
        ]);
      });
      return { [graphName]: result };
    },
    formatAllStatsToExcel() {
      return {
        ...this.formatToExcel(this.filterItems(this.systemRating), this.$t('general.location'))
      };
    },
    toggleSortMode() {
      if (this.sortMode === 'desc') {
        this.sortMode = 'asc';
      } else {
        this.sortMode = 'desc';
      }
    },
    clearChartData() {
      this.cpuCharts.series[0].data = [];
      this.memoryCharts.series[0].data = [];
      this.txRxChart.series[0].data = [];
      this.txRxChart.series[1].data = [];
      this.txRxChart.series[2].data = [];
      this.txRxChartPerUser.series[0].data = [];
      this.txRxChartPerUser.series[1].data = [];
      this.txRxChartPerUser.series[2].data = [];
      this.clientsChart.series[0].data = [];
      this.txRxRateChart.series[0].data = [];
      this.txRxRateChart.series[1].data = [];
      this.txRxRateChart.series[2].data = [];
      this.txRxRateChartPerUser.series[0].data = [];
      this.txRxRateChartPerUser.series[1].data = [];
      this.txRxRateChartPerUser.series[2].data = [];
    },
    handleDateChangeForStat() {
      // statSystemService.responseStats(this);
      if (!this.isShowingDateRangeLimitExceededErrorForStat) {
        statSystemService.responseStats(this);
      }
    },
    handleDateChangeForRating() {
      // statSystemService.getSystemRating(this);
      if (!this.isShowingDateRangeLimitExceededErrorForRating) {
        statSystemService.getSystemRating(this);
      }
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    memoryLoad(total, free) {
      let memLoad = ((total - free) * 100) / total;
      if (!memLoad) {
        memLoad = 0;
        return memLoad;
      }
      return memLoad;
    },
    formatToCsv(locations) {
      if (!locations) {
        return [];
      }

      const csvLocations = JSON.parse(JSON.stringify(locations));

      for (const location of csvLocations) {
        let companyName = '';
        if (this.locationPartyGetField(location.location_id, 'name') !== '') {
          // console.log(this.locationPartyGetField(locationId, 'name'));
          companyName = this.locationPartyGetField(location.location_id, 'name');
        } else {
          companyName = '';
        }
        location.companyName = companyName;
      }

      // const result = csvLocations.map((location) => ({
      //   Location: location.location_name ? location.location_name : '',
      //   'Company': location.companyName,
      //   'Traffic Downloaded': this.formatBytes(location.traffic_rx),
      //   'Traffic Uploaded': this.formatBytes(location.traffic_tx),
      //   'Total Clients': location.total_clients ? location.total_clients : 0,
      //   'CPU Load': location.cpu_load ? `${(location.cpu_load * 100).toFixed(2)}%` : '0%',
      //   'Memory Load':
      //     location.memory_total && location.memory_free ?
      //       `${this.memoryLoad(location.memory_total, location.memory_free).toFixed(2)}%` :
      //       '0%'
      // }));
      const locationI18n = this.$t('general.location');
      const companyI18n = this.$t('general.company');
      const trafficDownloadedI18n = this.$t('general.trafficDownloaded');
      const trafficUploadedI18n = this.$t('general.trafficUploaded');
      const totalClientsI18n = this.$t('general.totalClients');
      const cpuLoadI18n = this.$t('general.cpuLoad');
      const memoryLoadI18n = this.$t('general.memoryLoad');

      const result = csvLocations.map((location) => {
        const mapResult = {};
        mapResult[locationI18n] = location.location_name ? location.location_name : '';
        mapResult[companyI18n] = location.companyName ? location.companyName : this.$t('general.no').toLowerCase();
        mapResult[trafficDownloadedI18n] = this.formatBytes(location.traffic_rx);
        mapResult[trafficUploadedI18n] = this.formatBytes(location.traffic_tx);
        mapResult[totalClientsI18n] = location.total_clients ? location.total_clients : 0;
        mapResult[cpuLoadI18n] = location.cpu_load ? `${(location.cpu_load * 100).toFixed(2)}%` : '0%';
        mapResult[memoryLoadI18n] =
          location.memory_total && location.memory_free ?
            `${this.memoryLoad(location.memory_total, location.memory_free).toFixed(2)}%` :
            '0%';
        return mapResult;
      });
      // console.log(result)
      return result;
    },
    formatBytes(bytes, decimals) {
      return commonService.formatBytes(bytes, decimals);
    },
    disableStatMode() {
      this.drPickerDate = {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      };
      this.isShowingDateRangeLimitExceededErrorForStat = false;
      this.dateRangeLimitExceededErrorRangeLimitForStat = 0;
      this.statSystemId = '';
      this.$store.commit('setLocationStatId', null);
    }
  },
  created() {
    this.$store.commit('setCpesList', false);
    statSystemService.getSystemRating(this);
    this.disableStatMode();
  },
  beforeDestroy() {},
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>
<style lang="scss">
.disabled-when-date-range-limit-exceeded-error {
  cursor: not-allowed !important;
}
.systems {
  display: flex;
}

.systems-rating-list {
  width: 100%;
}

.stat-visible .systems-rating-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.location-included {
  padding-left: 4rem !important;
}

.location-aggregated {
  padding-left: 2rem !important;
}

.system-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .system-stat {
  transition: width 0.2s;
  width: 75%;
}

.no-data-row {
  text-align: center;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.save-icon {
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.loader-backdrop-statsystem:after {
  position: absolute;
  top: 30px;
}
.no-scroll-bar {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll-bar::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
}
</style>
